import React from "react"
import Bandeau from "../components/bandeau"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Bandeau dark>
      <h1 className="darkText">Page indisponible</h1>
      <p>
        <Link className="darkLink" to="/">
          <u>Retourner à la page principale</u>
        </Link>
      </p>
    </Bandeau>
  </Layout>
)

export default NotFoundPage
